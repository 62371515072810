import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import { Container, Row, Col } from "react-bootstrap";
import TagList from "../../components/TemplateTag/TagList";
import MainTitle from "../../components/Common/MainTitle";
import PostCard from "../../components/Common/PostCard";

export const pageQuery = graphql`
  query {
    allTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/design/posts//" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
          }
        }
      }
    }

    filteredPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/design/posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY년 MM월 DD일")
            title
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const menu = "design";
const section = "posts";

const posts = ({ location, data }) => {
  return (
    <Layout location={location}>
      <SEO title={section} />

      <main role="main" style={{ marginTop: "56px" }}>
        <Container>
          <Row>
            <Col lg={2} className="d-none d-lg-block">
              <aside className="sticky-item">
                <TagList
                  tags={data.allTags.group}
                  menu={menu}
                  section={section}
                />
                {/* <ADBox /> */}
              </aside>
            </Col>

            <Col lg={10}>
              <div className="my-5">
                <MainTitle title="Posts" />
                <Row>
                  {data.filteredPosts.edges.map(({ node }) => {
                    return (
                      <Col
                        key={node.fields.slug}
                        xl={6}
                        style={{ marginBottom: "20px" }}
                      >
                        <PostCard node={node} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default posts;
